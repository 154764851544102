.MuiTab-root {
  font-family: 'CentraNo2-Book', 'CentraNo2', Roboto, sans-serif !important;
  color: #012746 !important;
  min-width: 10rem !important;
  letter-spacing: 0.025rem;
  opacity: 0.7;

  &.Mui-selected {
    opacity: 1;
  }
}
