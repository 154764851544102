.ov-calendar {
  .rbc-month-view {
    border: 0;
  }

  .rbc-header {
    border-left: 0;
    color: rgba(0, 0, 0, 0.3);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-color: #f4f4f2;
  }

  .rbc-month-row {
    border-top: 0;
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 0;
  }

  .rbc-day-bg {
    &.rbc-selected-cell {
      background-color: transparent;
    }

    &.rbc-today {
      background-color: transparent;
    }
  }

  .rbc-date-cell {
    padding-right: 0.5rem;
  }

  .rbc-now {
    span {
      border: 1px solid;
    }
  }

  .rbc-off-range {
    &-bg {
      background-color: transparent;
    }

    &.rbc-date-cell {
      color: #012746;
      opacity: 0.3;
    }
  }
}
