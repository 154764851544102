.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #002846;
}

.rdrDayNumber span {
  color: #002846;
}

.rdrNextPrevButton, .rdrNextButton {
  border-radius: 50%;
}

.rdrMonthName, .rdrMonthAndYearPickers {
  text-align: center;
  color: #002846;
  font-size: 0.875rem;
}


