@font-face {
  font-family: 'CentraNo2-Book';
  src: url('./design-system/fonts/CentraNo2-Book.woff') format('woff'),
    url('./design-system/fonts/CentraNo2-Book.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'CentraNo2-Book';
  src: url('./design-system/fonts/CentraNo2-BookItalic.woff') format('woff'),
    url('./design-system/fonts/CentraNo2-BookItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'CentraNo2';
  src: url('./design-system/fonts/CentraNo2-Bold.woff') format('woff'),
    url('./design-system/fonts/CentraNo2-Bold.woff2') format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'CentraNo2';
  src: url('./design-system/fonts/CentraNo2-BoldItalic.woff') format('woff'),
    url('./design-system/fonts/CentraNo2-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'CentraNo2-Medium';
  src: url('./design-system/fonts/CentraNo2-Medium.woff') format('woff'),
    url('./design-system/fonts/CentraNo2-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CentraNo2';
  src: url('./design-system/fonts/CentraNo2-Bold.woff') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'CentraNo2';
  src: url('./design-system/fonts/CentraNo2-ExtraboldItalic.woff')
      format('woff'),
    url('./design-system/fonts/CentraNo2-ExtraboldItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}

body {
  background: #fafafa !important;
  font-family: 'CentraNo2-Book', 'CentraNo2', Roboto, sans-serif;
}
